<template>
    <div class="flex flex-column flex-auto">
        <div class="p-5">
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <p class="text-lg text-justify">Welcome to the submit piracy page. This page will allow you to submit URLs to us for investigation and takedown.
                        First of all you'll need to select an asset to submit the URLs for. After that paste your links into the big box and click submit.
                        We'll investigate the URLs for you and submit a DMCA takedown request on your behalf.
                    </p>
                </div>
            </div>

            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <form ref="emailform">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12 qp-float-label mb-4">
                                <AutoComplete class="w-full text-lg" v-model="selectedAsset" id="asset_name" name="asset_name" :suggestions="assets"
                                              :dropdown="true" @complete="searchAssets" optionLabel="name" placeholder="Asset" forceSelection />
                            </div>
                            <div class="field col-12 mb-4">
                                <Textarea v-model="submissionBody" id="message" name="message" :rows="15" :autoResize="true" class="py-3 px-2 text-lg" placeholder="Paste links here"></Textarea>
                            </div>
                            <div class="field col-12">
                                <InputText hidden v-model="username" id="from_name" name="from_name" type="text" placeholder="Email"/>
                            </div>
                            <div class="col-12 text-right">
                                <Button type="button" label="Submit" icon="pi pi-envelope" @click="submitEmail" class="px-5 py-3 w-full right-0"></Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    name: "Submit",

    data: function() {
        return {
            assets: null,
            submissionBody: null,
            selectedAsset: null,
            username: this.$store.getters.getUserInfo.username,
        }
    },

    methods: {
        searchAssets(event) {
            this.assets = [];
            let activeAssets = this.$store.getters.getActiveAssetData;

            if (event.query.length < 3) {
                activeAssets.forEach(asset => this.assets.push(asset.name) )
            } else {
                activeAssets.forEach(
                    asset => {
                        if (asset.name.toLowerCase().includes(event.query.toLowerCase())) {
                            this.assets.push(asset.name);
                        }
                    }
                )
            }
        },

        submitEmail() {
            console.log(this.username)

            emailjs.sendForm('service_patql9m', 'template_5mpy4qs', this.$refs.emailform, 'UBWkZjmhUXwFvHQjx')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                    this.$emitter.emit("good-toast", "Thank you for your submission, we'll action this immediately");
                    this.submissionBody = "";
                    this.selectedAsset = "";
                }, (error) => {
                    console.log('FAILED...', error.text);
                    this.$emitter.emit("bad-toast", "Something isn't right, get in touch if this continues");
                });
        },
    },
}
</script>

<style scoped>

</style>
